// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import usuarios from './usuarios'
import pwa from './PWA'
import application from './application'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  usuarios,
  pwa,
  application
})

export default rootReducer

// Initial State
const initialState = {
    displayMode: `browser`,
    isPwaInstalled: false,
    transfer: ``,
    connectionUserAgentReported: false
}

const pwaReducer = (state = initialState, action) => {
    switch (action.type) {
        case `UPDATE_DISPLAY_MODE`:
            return {...state, displayMode: action.val}
        case `UPDATE_PWA_INSTALLED`:
            return {...state, isPwaInstalled: action.val}
        case `UPDATE_TRANSFER`:
            return {...state, transfer: action.val}
        case `UPDATE_CONNECTION_USER_AGENT_REPORTED`:
            return {...state, connectionUserAgentReported: action.val}
        default:
            return state
    }
}

export default pwaReducer

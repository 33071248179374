// ** Initial State
const initialState = {
    list: [],
    usuario: null
  }

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_USERS':
        return { ...state, list: action.data }
      case 'GET_USER':
        return { ...state, usuario: action.data }
      default:
        return state
    }
  }
  
  export default layoutReducer
// Initial State
const initialState = {
    encuestaProcede: true,
    encuestaConservaNido: true
}

const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case `UPDATE_ENCUESTA_PROCEDE`:
            return {...state, encuestaProcede: action.val}
        case `UPDATE_ENCUESTA_CONSERVA_NIDO`:
            return {...state, encuestaConservaNido: action.val}
        default:
            return state
    }
}

export default applicationReducer
